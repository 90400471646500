<template>
  <div class="quick-tool-bar">
    <div class="win-operate-list clearfix">
      <!-- <div class="button-item pull-left min" @click="onOperateClicked('min')"></div>
      <div class="button-item pull-left close" @click="onOperateClicked('close')"></div> -->
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
    };
  },
  methods: {
    onOperateClicked(type){
      if(type == "min"){
        window.winApi && window.winApi.minimizeWindow()
      }
      if(type == "close"){
        window.winApi && window.winApi.closeWindow()
      }
    }
  },
  watch: {
  },
  computed: {},
  created() {},
  mounted() {},
};
</script>
<style lang='scss'>
.quick-tool-bar{
  height: 56px;
  position: relative;
  margin-left: 20px;
  .win-operate-list{
    height: 100%;
    width: 168px;
    .button-item{
      width: 56px;
      height: 56px;
      cursor: pointer;
      background-repeat: no-repeat;
      background-size: 26px;
      background-position: center;
    }
    .button-item.min{
      background-image: url('~@Account/images/tool/icon_min_light@2x.png');
    }
    .button-item.close{
      background-image: url('~@Account/images/tool/icon_close_light@2x.png');
    }
  }
}

// 媒体查询
@media screen and (max-width: 1500px) {
  .quick-tool-bar {
    margin-left: 10px;
    .win-operate-list {
      width: 108px;
      .button-item {
        width: 36px;
        height: 56px;
        cursor: pointer;
        background-repeat: no-repeat;
        background-size: 26px;
        background-position: center;
        opacity: 1;
      }
    }
  }
}
</style>