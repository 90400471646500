<template>
  <div class="module-select-container">
    <div class="nav-header top-bar">
      <!-- 左侧的logo -->
      <!-- <div class="layout-select system-select"> -->
      <div class="layout-select nav-bar">
        <img v-cdnimg class="nav-logo" :src="logoUrl" alt="">
      </div>
      <div class="user-info clearfix">
        <div class="area-name pull-left">{{ userAreaName }}</div>
        <div class="logeed pull-left">
          <el-popover placement="bottom" trigger="hover" popper-class="system-popper">
            <div class="operate-list">
              <div class="operate-item" @click="onPersonalClicked">个人中心</div>
              <div class="operate-item" @click="onAboutClicked">关于</div>
              <div class="operate-item" @click="onLogOutClicked">退出</div>
            </div>
            <div slot="reference" class="clearfix">
              <el-avatar class="logged-avatar pull-left" :src="account.avatar">
                <img v-cdnimg src="/Account/images/default-user.png" />
              </el-avatar>
              <span class="logeed-name pull-left">{{ account.realname || account.loginname }}</span>
            </div>
          </el-popover>
        </div>
        <div class="layout-select role-info-select pull-left">
          <el-popover
            popper-class="user-rolespane-popper"
            placement="bottom-end"
            width="344"
            trigger="click"
          >
            <div class="user-roles-pane">
              <div class="curr-role-show">
                当前身份是
                <span>{{ userSelectRoleVal }}</span>，可以切换到其他身份。
              </div>
              <div class="role-optional-list">
                <div v-for="(item,index) in userRoleList" :key="index">
                  <div class="role-optional-item" @click="onUserRoleChange(item)">
                    <div class="role-optional-content">
                      <div
                        class="radio"
                        :class="{'radio-select': item.roleid == userRole.roleid && item.schoolname == userRole.schoolname && item.zonename == userRole.zonename}"
                      ></div>
                      <div class="role-name">{{ item.rolename }}</div>
                      <div class="role-origin">{{ item.schoolname || item.zonename }}</div>
                    </div>
                  </div>
                  <!-- 分割线 -->
                  <div class="splite-line" v-if="index != userRoleList.length - 1"></div>
                </div>
              </div>
            </div>
            <div slot="reference" class="user-rolespane-reference">
              {{ userSelectRoleVal }}
              <i class="el-icon-arrow-down"></i>
            </div>
          </el-popover>
        </div>
        <quick-tool-bar-inline v-if="showToolBar" class="pull-left" />
      </div>
    </div>
    <div class="body-content">
      <div class="select-option" v-if="isValidArray(bgSysList)">
        <div class="option-logo">
          <img v-cdnimg src="/Account/images/signin/class-manage.png" @click="clickClassManage" alt />
        </div>
        <div class="option-name">
          <div class="option-name-text">班级管理</div>
          <div class="option-name-shadow"></div>
        </div>
        <div class="option-text">游戏化教学特色，丰富课堂互动，多维度学生评价，助力教师高效管理家校关系，携手家长共育。</div>
        <div class="option-button" @click="clickClassManage">
          开始使用
          <img v-cdnimg src="/Account/images/signin/lenovo-module-right.png" alt />
        </div>
      </div>
      <div class="select-option" v-if="isValidArray(cmSysList)">
        <div class="option-logo">
          <img v-cdnimg src="/Account/images/signin/smart-jy.png" @click="clickSmartManage" alt />
        </div>
        <div class="option-name">
          <div class="option-name-text">智慧教研</div>
          <div class="option-name-shadow"></div>
        </div>
        <div class="option-text">查看教学全过程数据，参与集体备课，扫码听课评课，助力教师专业成长。</div>
        <div class="option-button" @click="clickSmartManage">
          开始使用
          <img v-cdnimg src="/Account/images/signin/lenovo-module-right.png" alt />
        </div>
      </div>
      <div class="empty-show" v-if="!isValidArray(cmSysList) && !isValidArray(bgSysList)">
        <img v-cdnimg src="/Account/images/signin/image_缺省图.png" alt="">
        <div class="empty-title">该账号暂无权限，请联系管理员配置</div>
      </div>
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>
<script>
const OuterLinkURL = {
  // 登录
  login: location.origin + "/Account/SignIn",
  // 个人中心
  personal: location.origin + "/Account/Setting",
  // 切换角色
  changerole: location.origin + "/Account/LenovoModuleSelect",
  // 统计
  st: location.origin + "/St/",
  // 教研
  cm: location.origin + "/Cm/",
  // 资源
  res: location.origin + "/Res/",
  // 后台
  console: location.origin + "/Console/",
  // 班管
  bg: location.origin + "/Bg/",
  // 关于
  about: location.origin + "/Account/LenovoAbout",
};
import {
  NEW_BING_ACCOUNT_SESSION,
  GETROLESTRUCTURE,
  GETACCOUNTROLE,
  GETACCOUNTBYSESSION,
  LOGOUT,
  SETROLEISEMODULE,
  GETCONFIGINFO
} from "@/http/api";
import store from "@/store";
import Vue from "vue"
import quickToolBarInline from '@/components/quickToolBarInline.vue'
import Footer from '@/components/signin/Widgets/Footer.vue';
export default {
  data() {
    return {
      userRoleVal: "",
      cmSysList: [],
      bgSysList: [],
      showToolBar: false,
      logoUrl:'',    // 左上角logo地址
    };
  },
  components: {
    quickToolBarInline,
    Footer
  },
  beforeRouteEnter(to, from, next) {
    document.title = ''
    // 未登录跳转登录页
    const _this = Vue.prototype;
    const session = _this.getSession();
    if (!session) {
      location.href = OuterLinkURL.login;
    } else {
      // 初次进入
      let state = store.state;
      let roleList = state.roleList;
      if (!_this.isValidArray(roleList)) {
        // 联想学科教研 lenovoxkjy
        // 联想数据分析 lenovosjfx
        // 联想教研资源 lenovojyzy
        // 联想基础管理 lenovojcgl
        // 联想班级管理 lenovobjgl
        const cmAliaList = [
          "lenovoxkjy",
          "lenovosjfx",
          "lenovojyzy",
          "lenovojcgl",
        ];
        const bgAliaList = ["lenovobjgl"];
        GETROLESTRUCTURE(
          _this.$qs.stringify({ session: _this.getSession() })
        ).then((result) => {
          let list = result.data.result;
          // 配置导航
          if (_this.isValidArray(list)) {
            var cmSysList = [];
            var bgSysList = [];
            list.forEach((item) => {
              let alia = item.StructureAlias;
              if (cmAliaList.indexOf(alia) >= 0) {
                cmSysList.push(item);
              }
              if (bgAliaList.indexOf(alia) >= 0) {
                bgSysList.push(item);
              }
            });
            // 既有 教研 又有班管
            if (
              _this.isValidArray(cmSysList) &&
              _this.isValidArray(bgSysList)
            ) {
              next((vm) => {
                vm.cmSysList = cmSysList;
                vm.bgSysList = bgSysList;
              });
            } else if (_this.isValidArray(cmSysList) || _this.isValidArray(bgSysList)) {
              const moduleIdx = bgSysList.length ? 1 : 2;
              const item = bgSysList.length ? bgSysList : cmSysList;
              const param = {
                session: _this.getSession(),
                moduleIdx,
              }
              SETROLEISEMODULE(_this.$qs.stringify(param));
              location.href = item[0].StructureUrl;
            } else {
              next()
            }
          }else{
            next()
          }
        });
      } else {
        next();
      }
    }
  },
  computed: {
    userSelectRoleVal() {
      let findResult = this.userRoleList.find((item) => {
        return item.roleid == this.userRoleVal;
      });
      return findResult && findResult.rolename
    },
    userRoleList() {
      return this.$store.state.roleList;
    },
    userRole() {
      return this.$store.state.role;
    },
    account() {
      return this.loadAccount();
    },
    userAreaName() {
        let role = this.userRole
        let name = ""
        if(this.isValidArray(Object.keys(role))){
            let platformname = role.platformname
            let zonename = role.zonename
            let schoolname = role.schoolname
            if(platformname && platformname != ""){
                name = platformname
            }
            if(zonename && zonename != ""){
                name = zonename
            }
            if(schoolname && schoolname != ""){
                name = schoolname
            }
        }
        return name
    }
  },
  watch: {
    userRole: {
      handler(newVal) {
        this.userRoleVal = newVal.roleid;
      },
      deep: true,
      immediate: true,
    },
    '$route': {
      handler(){
        var device = this.getSessionVal("device")
        if(device && device == "lenovowin"){
          this.showToolBar = true
        }
        if(window.winApi){
          this.showToolBar = true
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    getLogoConfig(){    // 获取左上角logo的配置
      let sendKey = window.location.host;
      if (window.location.host.includes("localhost") || window.location.host.includes("8080")) {
          sendKey = "educenter-lenovo.forclass.net"; //lenovo
          // sendKey = "test-ca.forclass.net";  //forclass
      }
      // sendKey = "test-ca.forclass.net";  //forclass
      GETCONFIGINFO({ recourseKey: sendKey, recourseType:'ClassAssistant' })
          .then(data => {
              const res = data.data.data
              if (res.resultText) { 
                  this.logoUrl = res.resultText.jyweblogo
                  document.title = res.resultText.jywebtitle
              }
              // resolve(res);
          })
          .catch((error) => {
              // reject(error);
          })
    },
    clickClassManage() {
      const param = {
        session: this.getSession(),
        moduleIdx: 1 //1班级管理,2教研管理
      }
      SETROLEISEMODULE(this.$qs.stringify(param)).then(result=>{
        if (window.location.host.includes("localhost") || window.location.host.includes("8080")) {
          location.href = 'http://localhost:8080/Bg/';
          return
        }
        location.href = this.bgSysList[0].StructureUrl;
      })
    },
    clickSmartManage(href) {
      const param = {
        session: this.getSession(),
        moduleIdx: 2 //1班级管理,2教研管理
      }
      SETROLEISEMODULE(this.$qs.stringify(param)).then(result=>{
        location.href = this.cmSysList[0].StructureUrl;
      })
    },
    handleRepeatRole(list) {
      // 去除重复角色
      let finalList = [];
      for (let i = 0; i < list.length; i++) {
        let uniqueFlag = true; // 改角色是否和之前的列表里有重复，是否是独一无二的
        finalList.forEach((item) => {
          if (
            item.platformid == list[i].platformid &&
            item.zoneid == list[i].zoneid &&
            item.schoolid == list[i].schoolid &&
            item.roleid == list[i].roleid
          ) {
            uniqueFlag = false; // 说明有重复的角色
          }
        });
        if (uniqueFlag == true) {
          finalList.push(list[i]);
        }
      }
      return finalList;
    },
    onUserRoleChange(item) {
      const param = {
        session: this.getSession(),
        platformId: item.platformid,
        roleId: item.roleid,
        zoneId: item.zoneid,
        schoolId: item.schoolid,
      };
      NEW_BING_ACCOUNT_SESSION(this.$qs.stringify(param)).then((result) => {
        result = result.data;
        if (result.ReturnCode == 1) {
          this.updateLocalUserInfo(item);
          location.href = OuterLinkURL.changerole + "?t="+ new Date().getTime();
        }
      });
    },
    updateLocalUserInfo(item) {
      // 选择角色之后需要替换一下保存在cookie和sessionStorage里的信息
      let sessionAccount = JSON.parse(sessionStorage.getItem("account"));
      sessionAccount.role = item.rolename;
      sessionStorage.setItem("account", JSON.stringify(sessionAccount));
      // 更新cookie信息
      let cookieAccount = JSON.parse(this.Cookies.get("account"));
      cookieAccount.classidx = item.classid;
      cookieAccount.classname = item.classname || "";
      cookieAccount.role = item.rolename;
      cookieAccount.roleidx = item.roleid;
      cookieAccount.schoolidx = item.schoolid;
      cookieAccount.schoolname = item.schoolname || "";
      cookieAccount.zoneidx = item.zoneid;
      cookieAccount.zonename = item.zonename;

      this.$store.commit({
        type: "UPDATE_ACCOUNT",
        account: cookieAccount,
      });
    },
    onPersonalClicked() {
      location.href = `${OuterLinkURL.personal}?path=${this.$route.path}`;
    },
    onAboutClicked() {
      location.href = OuterLinkURL.about;
    },
    onLogOutClicked() {
      LOGOUT(this.$qs.stringify({ session: this.getSession() })).then(() => {
        // 移除cookie
        this.$store.commit({
          type: "UPDATE_ACCOUNT",
          account: null,
        });
        // 移除 sessionStorage
        sessionStorage.removeItem("account");
        var hostName = window.location.hostname;
        var domain = hostName;
        if (domain != "localhost"){
            // 清除本地cookie
            this.setCookie("account", null, {
            expires: -1,
            domain: this.getDomain(),
            });
        }
        this.$message.success({
          message: "登出成功",
          duration: 500,
          onClose: () => {
            location.href = OuterLinkURL.login;
          },
        });
      });
    },
  },
  beforeCreate(){
    // document.title = ''
  },
  created() {
    this.getLogoConfig()   // 获取左上角logo的配置
    // 获取当前用户的角色列表
    const param = {
      session: this.getSession(),
      hostName: location.hostname,
    };
    if (param.hostName == "localhost") {
      param.hostName = process.env.VUE_APP_HOSTNAME;
    }
    
    GETACCOUNTROLE(this.$qs.stringify(param)).then((result) => {
      result = result.data.result;
      // 用户角色列表
      if (this.isValidArray(result)) {
        let info = result[0];
        let userRoles = info.roles;
        if (this.isValidArray(userRoles)) {
          let roles = this.handleRepeatRole(userRoles);
          store.commit("UPDATE_USER_ROLE", roles);
          // 获取当前用户的选定角色
          GETACCOUNTBYSESSION(
            this.$qs.stringify({ session: this.getSession() })
          ).then((result) => {
            result = result.data.result;
            if (this.isValidArray(result)) {
              let info = result[0];
              let userRoles = info.roles;
              if (this.isValidArray(userRoles)) {
                let selRoles = this.handleRepeatRole(userRoles);
                store.commit("UPDATE_USER_SELECT_ROLE", selRoles[0]);
              }
            }
          });
        } else {
         // console.log("用户角色为空");
        }
      }
    });
  },
};
</script>
<style lang="scss">
.module-select-container {
  width: 100%;
  height: 100vh;
  .nav-header {
    height: 56px;
    width: 100%;
    background: #3b404d;
    display: flex;
  }
  .body-content {
    background: #ffffff url("~@Account/images/signin/lenovoModuleBackground.png")
      no-repeat;
    width: 100%;
    background-size: 100% 100%;
    height: calc(100vh - 56px);
    display: flex;
    align-items: center;
    justify-content: center;
    .select-option {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 640px;
      height: 480px;
      border-radius: 16px;
      background: #ffffff;
      .option-logo {
        margin-top: 64px;
      }
      .option-logo img {
        cursor: pointer;
        max-width: 200px;
        height: 156px;
      }
      .option-name {
        margin-top: 58px;
        font-size: 24px;
        color: #131414;
        font-weight: 700;
        position: relative;
        .option-name-text {
          position: relative;
          z-index: 100;
        }
        .option-name-shadow {
          width: calc(100% + 8px);
          height: 8px;
          background: rgba(255, 106, 0, 0.48);
          border-radius: 4px;
          left: -4px;
          position: absolute;
          bottom: 0;
        }
      }
      .option-text {
        width: 352px;
        margin-top: 16px;
        color: #606266;
        line-height: 22px;
        text-align: center;
      }
      .option-button {
        width: 116px;
        height: 40px;
        background: #ff6a00;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 24px;
        color: #ffffff;
        cursor: pointer;
        font-size: 14px;
      }
      .option-button img {
        width: 8px;
        margin-left: 4px;
      }
    }
    .select-option:first-child {
      margin-right: 64px;
    }
  }
}
.pull-left {
  float: left;
}
.pull-right {
  float: right;
}
.clearfix {
  clear: both;
  zoom: 1;
}
.clearfix:after {
  content: "";
  display: block;
  height: 0;
  line-height: 0;
  clear: both;
  visibility: hidden;
}
.top-bar {
  width: 100%;
  height: 56px;
  line-height: 56px;
  background-color: #3b404d;
  display: flex;
  align-items: center;
}
.nav-bar {
  display: flex;
}
.system-select {
  position: absolute;
  left: 32px;
  width: 252px;
  height: 56px;
  top: 0;
  font-size: 20px;
  color: #fff;
  background-image: url('~@Account/images/lenovo-logo.png');
  background-size: auto 24px;
  background-repeat: no-repeat;
  background-position: center;
}
.nav-logo{
  max-width: 252px;
  margin-left: 32px;
}
.layout-select .el-input__inner,
.layout-select .el-select .el-input.is-focus .el-input__inner {
  background: inherit;
  font-size: 14px;
  color: #fff;
  padding: 0 20px 0 20px;
  border: none;
  position: relative;
  text-align: center;
}
.layout-select .el-input__inner,
.layout-select .el-select .el-input.is-focus .el-input__inner {
  background: inherit;
  font-size: 14px;
  color: #fff;
  padding: 0 20px 0 20px;
  border: none;
  position: relative;
  text-align: center;
}
.layout-select .el-select .el-input .el-select__caret {
  font-size: 14px;
}
.layout-select .el-input__suffix {
  right: 0;
  line-height: 40px;
}
.layout-select .el-select-dropdown__list {
  text-align: center;
}
.layout-select .el-select-dropdown__list .el-select-dropdown__item {
  font-size: 16px;
}
.layout-select .el-input__suffix-inner {
  top: -1px;
  position: relative;
}
.layout-switch {
  width: 84px;
  display: inline-block;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  background-image: url(/Console/images/icon_switch.png);
  background-size: 28px;
  background-position: right center;
  background-repeat: no-repeat;
}
.top-nav-list {
  text-align: center;
  font-size: 16px;
}
.top-nav-item {
  display: inline-block;
  color: rgba(255, 255, 255, 0.8);
  margin: 0 32px;
  cursor: pointer;
}
.top-nav-item.active {
  font-weight: 700;
  color: #fff;
}
.content {
  background-color: #fff;
}
.left-bar {
  width: 168px;
  height: calc(100vh - 56px);
  position: fixed;
  left: 0;
  top: 56px;
  background-color: #fff;
  padding-top: 16px;
  box-sizing: border-box;
  z-index: 10;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 12px rgba(0, 0, 0, 0.03),
    0px 4px 16px 2px rgba(0, 0, 0, 0.02);
}
.nav-list {
  margin: 0 auto;
}

.left-nav-item {
  width: 100%;
  height: 46px;
  line-height: 46px;
  text-align: left;
  cursor: pointer;
  position: relative;
  font-size: 14px;
  font-family: "Microsoft YaHei UI", Microsoft YaHei, "微软雅黑", Tahoma, Arial,
    Verdana, sans-serif;
  padding-left: 33px;
}
.left-nav-item.active {
  color: #f15f00;
}
.left-nav-item img {
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-right: 9px;
}
.user-info {
  position: absolute;
  right: 0;
  top: 0;
  padding-right: 32px;
}
.logeed {
  cursor: pointer;
}
.logged-avatar {
  width: 24px;
  height: 24px;
  line-height: 1;
  display: inline-block;
  background-color: #fff;
  margin-top: 16px;
  img{
    width: 100%;
    object-fit: fill !important;
  }
}
.logeed .logeed-name {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  margin-left: 8px;
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.operate-list {
  text-align: center;
  background: #3b404d;
  border-color: #3b404d;
  color: #fff;
  font-size: 14px;
}
.operate-item {
  height: 34px;
  line-height: 34px;
  cursor: pointer;
}
.operate-item:hover {
  background-color: #ddd;
  color: #1b2a51;
}
.area-name{
    color: #fff;
    margin-right: 20px;
}
.system-popper {
  background: #3b404d;
  padding: 10px 0;
}
.system-popper[x-placement^="bottom"] .popper__arrow::after {
  border-bottom-color: #3b404d;
}
.system-popper[x-placement^="bottom"] {
  margin-top: 6px;
}
.system-popper {
  background: #3b404d;
  padding: 10px 0;
}
.system-popper[x-placement^="bottom"] .popper__arrow::after {
  border-bottom-color: #3b404d;
}
.system-popper[x-placement^="bottom"] {
  margin-top: 6px;
}
.splite-line {
  width: 100%;
  height: 0px;
  border: 1px solid#F2F6FC;
}
.user-rolespane-popper {
  padding: 0;
  transform: translateX(-20px);
  .curr-role-show {
    background: #f5f7fa;
    padding: 16px 24px;
    color: #131414;
    line-height: 22px;
    span {
      color: #ff6a00;
    }
  }
  .role-optional-list {
    max-height: 300px;
    overflow-y: auto;
    box-sizing: border-box;
    overflow-x: hidden;
    .role-optional-item {
      padding: 8px 24px 12px;
      min-height: 62px;
      box-sizing: border-box;
      cursor: pointer;
      .role-optional-content {
        padding-left: 22px;
        position: relative;
        .radio {
          width: 14px;
          height: 14px;
          background-image: url(~@Account/images/tool/radio-1.png);
          background-size: 100%;
          background-position: 0 center;
          position: absolute;
          left: 0;
          top: 14px;
        }
        .radio-select {
          background-image: url(~@Account/images/tool/radio.png);
        }
        .role-name {
          color: #131414;
          font-weight: 700;
          font-size: 12px;
          line-height: 20px;
        }
        .role-origin {
          color: #606266;
          font-size: 12px;
          margin-top: 2px;
          line-height: 20px;
        }
      }
    }
  }
}
.user-rolespane-reference{
  text-align: center;
  background: #3b404d;
  border-color: #3b404d;
  color: #ddd;
  font-size: 14px;
  padding: 0 28px 0 10px;
  position: relative;
  text-overflow :ellipsis; 
  white-space :nowrap;
  overflow : hidden;
  cursor: pointer;
  i {
    position: absolute;
    right: 7px;
    top: 21px;
  }
}
</style>
<style lang="scss">
.empty-show {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -45%);
    text-align: center;
    img {
      width: 220px;
      margin-bottom: 20px;
    }
    .empty-title {
      color: #c0c4cc;
      cursor: default;
    }
  }
</style>
