<template>
  <div class="footer">
    <div v-if="onInitPlatform() && !isLenovoWeb" class="footer-info">
      <img v-cdnimg src="/Account/images/signin/tips.png" />
      <span>请使用Chrome 80+，Firefox 110+，360 14+，Edge 80+，QQ 11+，搜狗 11+，Safari 16+浏览器</span>
      <span class="down-brower" @click="onDownBrower">下载Chrome浏览器</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: [],
  components: {},
  data() {
    return {};
  },
  computed: {
    Platform() {
      return this.$store.state.platform;
    },
    isLenovoWeb() {
      return window.winApi ? true : false;
    }
  },
  methods: {
    onInitPlatform() {
      return this.Platform.PlatformSystemNumber == '000040' || this.Platform.PlatformSystemNumber == '000041' ? true : false;
    },
    // 下载浏览器
    onDownBrower() {
      let url = 'https://www.google.cn/chrome/';
      let a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.click();
      window.URL.revokeObjectURL(url);
    }
  },
  created() {},
  mounted() {}
};
</script>

<style lang="scss" scoped>
.footer {
  .footer-info {
    display: flex;
    align-items: center;
    padding: 32px;
    justify-content: center;
    cursor: default;
    color: #606266;
    font-size: 13px;
    img {
        margin-right: 12px;
    }
    .down-brower {
      cursor: pointer;
      color: #4E82E9;
      margin-left: 40px;
      font-size: 12px;
    }
  }
}
</style>
